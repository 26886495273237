import {
  ConsultantSale,
  Install,
  InstallRejected,
  Login,
  Onboarding,
  Settings,
  Products,
  SyncProducts,
} from "pages";
import { CampaignAdd, CampaignEdit, CampaignList } from "pages/Campaign";
import Report from "pages/Report";

const PublicRoutes: any = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/onboarding",
    component: Onboarding,
  },
  {
    path: "/install/rejected",
    component: InstallRejected,
  },
  {
    path: "/install",
    component: Install,
  },
  {
    path: "/register-trial",
    component: ConsultantSale,
  },
];

const ProtectedRoutes: any = [
  {
    path: "/",
    component: CampaignList,
  },
  {
    path: "/campaigns/add",
    component: CampaignAdd,
  },
  {
    path: "/campaigns/:id",
    component: CampaignEdit,
  },
  {
    path: "/campaigns",
    component: CampaignList,
  },
  {
    path: "/reports",
    component: Report,
  },
  {
    path: "/settings",
    component: Settings,
  },
  {
    path: "/productstatus",
    component: Products,
  },
  {
    path: "/syncproducts/:id",
    layout: null,
    component: SyncProducts,
  },
];

export { PublicRoutes, ProtectedRoutes };
