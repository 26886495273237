/* eslint-disable react-hooks/exhaustive-deps */
// Hook use Effect once time => the same componentDidmount
import { EffectCallback, useEffect } from "react";

function useEffectOnce(effect: EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
}

export default useEffectOnce;
