/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigateOmni } from "hooks";
import { useAuth } from ".";

export const RequireAuth = (props: any) => {
  const navigateOmni = useNavigateOmni();
  const { stateAuth } = useAuth();

  const useEffectDidMount = (effect: React.EffectCallback) => {
    useEffect(effect, [stateAuth]);
  };
  useEffectDidMount((): void => {
    if (!stateAuth.isActive) {
      return navigateOmni("/register-trial");
    } else if (!stateAuth.isLogin) {
      return navigateOmni("/login");
    }
  });

  const isReady = () => {
    return (
      stateAuth.isActive && stateAuth.isLogin
    );
  };

  return isReady() ? <>{props.children}</> : null;
};
