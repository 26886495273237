import {
  DEFAULT_ACTION,
  FETCH_REPORT,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_ERROR,
  FETCH_REPORT_TOP_ADS,
  FETCH_REPORT_TOP_ADS_SUCCESS,
  FETCH_REPORT_TOP_ADS_ERROR,
  SET_FILTER,
} from "./constants";

export const setDefaultAction = (payload: any) => ({
  type: DEFAULT_ACTION,
  payload,
});

export const fetchReport = (payload: any) => ({
  type: FETCH_REPORT,
  payload,
});

export const fetchReportSuccess = (payload: any) => ({
  type: FETCH_REPORT_SUCCESS,
  payload,
});

export const fetchReportError = (payload: any) => ({
  type: FETCH_REPORT_ERROR,
  payload,
});

export const fetchReportTopAds = (payload: any) => ({
  type: FETCH_REPORT_TOP_ADS,
  payload,
});

export const fetchReportTopAdsSuccess = (payload: any) => ({
  type: FETCH_REPORT_TOP_ADS_SUCCESS,
  payload,
});

export const fetchReportTopAdsError = (payload: any) => ({
  type: FETCH_REPORT_TOP_ADS_ERROR,
  payload,
});

export const setFilter = (payload: any) => ({
  type: SET_FILTER,
  payload,
});
