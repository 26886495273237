import { useContext } from "react";
import { AppContext } from "../appContext";

const useEmbed = () => {
  const { shop, shopStatus, app, setLocale }: any = useContext(AppContext);
  return {
    shop,
    shopStatus,
    app,
    fetchJson: app?.fetchJson,
    redirectToInstallApp: app?.redirectToInstallApp,
    redirectToUrl: app?.redirectToUrl,
    redirectToHaravanAdmin: app?.redirectToHaravanAdmin,
    push: app?.push,
    navigateOmni: app?.navigateOmni,
    setLocale,
    showMessege: app?.showMessege,
    showError: app?.showError,
    fullcreateFn: app?.fullcreateFn,
    fullcreateCloseFn: app?.fullcreateCloseFn,
  };
};

export default useEmbed;
