import { memo } from "react";

import * as Sentry from "@sentry/react";
import { Routes } from "react-router-dom";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const SentryWrapper = (props) => {
  return <SentryRoutes>{props.children}</SentryRoutes>;
};

export default memo(SentryWrapper);
