import { LazyLoad } from "utils/Loadable";

const Main = LazyLoad({ _cb: () => import("./Main") });
const TopAds = LazyLoad({ _cb: () => import("./TopAds") });
const ViewClick = LazyLoad({ _cb: () => import("./ViewClick") });
const OrderCost = LazyLoad({ _cb: () => import("./OrderCost") });
const Summary = LazyLoad({ _cb: () => import("./Summary") });
const HeaderFilters = LazyLoad({
  _cb: () => import("./HeaderFilters"),
});

export { Main, TopAds, ViewClick, OrderCost, Summary, HeaderFilters };
