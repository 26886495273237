import {
  DEFAULT_ACTION,
  DEFAULT_REPORT_DATA,
  FILTER_DEFAULT,
  FETCH_REPORT,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_ERROR,
  FETCH_REPORT_TOP_ADS,
  FETCH_REPORT_TOP_ADS_SUCCESS,
  FETCH_REPORT_TOP_ADS_ERROR,
  SET_FILTER,
} from "./constants";
export const initialState = {
  defaultState: false,
  error: "",
  filters: FILTER_DEFAULT,
  loadingReport: false,
  reports: DEFAULT_REPORT_DATA,
  loadingReportTopAds: false,
  reportTopAds: [],
  loadingCampaign: false,
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case DEFAULT_ACTION:
      return { ...state, defaultState: action.payload };

    case FETCH_REPORT:
      return { ...state, loadingReport: true };

    case FETCH_REPORT_SUCCESS:
      return { ...state, loadingReport: false, reports: action.payload };

    case FETCH_REPORT_ERROR:
      return { ...state, loadingReport: false, error: action.payload };

    case FETCH_REPORT_TOP_ADS:
      return { ...state, loadingReportTopAds: true };

    case FETCH_REPORT_TOP_ADS_SUCCESS:
      return {
        ...state,
        loadingReportTopAds: false,
        reportTopAds: action.payload,
      };

    case FETCH_REPORT_TOP_ADS_ERROR:
      return { ...state, loadingReportTopAds: false, error: action.payload };

    case SET_FILTER:
      return { ...state, loadingCampaign: false, filters: action.payload };

    default:
      throw new Error("Action invalid");
  }
};

export default reducer;
