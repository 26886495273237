import { memo } from "react";
import { Loading as HLoading } from "@haravan/react-components";

const Loading = (props) => {
  const { height } = props;
  return (
    <div
      style={{ height: `${height}px` }}
      className="hrv-tta-d-flex hrv-tta-items-center hrv-tta-justify-center hrv-tta-align-center"
    >
      <HLoading />
    </div>
  );
};

Loading.defaultProps = {
  height: 560,
};

export default memo(Loading);
