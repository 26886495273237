import { useState, useEffect } from "react";

// Hook
export function useWindowSize() {
  // Create State Size
  const [windowSize, setWindowSize] = useState<any>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handle when page resize
    function handleResize() {
      // SetState width and height
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);

    // Call handler
    handleResize();

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}
