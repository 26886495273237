import moment from "moment";

const scope = "changeYourCopeName/";
export const DEFAULT_ACTION = scope + "DEFAULT_ACTION";
export const FETCH_REPORT = scope + "FETCH_REPORT";
export const FETCH_REPORT_SUCCESS = scope + "FETCH_REPORT_SUCCESS";
export const FETCH_REPORT_ERROR = scope + "FETCH_REPORT_ERROR";
export const FETCH_REPORT_TOP_ADS = scope + "FETCH_REPORT_TOP_ADS";
export const FETCH_REPORT_TOP_ADS_SUCCESS =
  scope + "FETCH_REPORT_TOP_ADS_SUCCESS";
export const FETCH_REPORT_TOP_ADS_ERROR = scope + "FETCH_REPORT_TOP_ADS_ERROR";
export const SET_FILTER = scope + "SET_FILTER";
export const FILTER_DEFAULT = {
  start_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
  end_date: moment().format("YYYY-MM-DD"),
};
export const DEFAULT_REPORT_DATA = {
  sum_view: 0,
  sum_click: 0,
  sum_order: 0,
  sum_order_value: 0,
  sum_cost: 0,
  roas: 0,
  orders: [
    {
      labels: "01/02/2022",
      order_value: 219000,
      cost: 310000,
      roas: 1.2,
      from_date: "2022-02-01T00:00:00",
      to_date: "2022-02-02T00:00:00",
    },
    {
      labels: "02/02/2022",
      order_value: 919000,
      cost: 410000,
      roas: 1.3,
      from_date: "2022-02-01T00:00:00",
      to_date: "2022-02-02T00:00:00",
    },
    {
      labels: "03/02/2022",
      order_value: 819000,
      cost: 510000,
      roas: 1.4,
      from_date: "2022-02-01T00:00:00",
      to_date: "2022-02-02T00:00:00",
    },
    {
      labels: "04/02/2022",
      order_value: 919000,
      cost: 510000,
      roas: 1.5,
      from_date: "2022-02-01T00:00:00",
      to_date: "2022-02-02T00:00:00",
    },
    {
      labels: "05/02/2022",
      order_value: 919000,
      cost: 710000,
      roas: 1.6,
      from_date: "2022-02-01T00:00:00",
      to_date: "2022-02-02T00:00:00",
    },
  ],
  views: [
    {
      labels: "01/02/2022",
      view: 301,
      click: 499,
      from_date: "2022-02-01T00:00:00",
      to_date: "2022-02-02T00:00:00",
    },
    {
      labels: "03/02/2022",
      view: 351,
      click: 599,
      from_date: "2022-02-01T00:00:00",
      to_date: "2022-02-02T00:00:00",
    },
    {
      labels: "05/02/2022",
      view: 381,
      click: 499,
      from_date: "2022-02-01T00:00:00",
      to_date: "2022-02-02T00:00:00",
    },
    {
      labels: "07/02/2022",
      view: 301,
      click: 699,
      from_date: "2022-02-01T00:00:00",
      to_date: "2022-02-02T00:00:00",
    },
    {
      labels: "09/02/2022",
      view: 421,
      click: 499,
      from_date: "2022-02-01T00:00:00",
      to_date: "2022-02-02T00:00:00",
    },
  ],
};
// export const DEFAULT_REPORT_TOP_ADS_DATA = [
//   {
//     ad_name: "Chiến dịch 1",
//     roas: "2",
//   },
//   {
//     ad_name: "Chiến dịch 2",
//     roas: "1,8",
//   },
//   {
//     ad_name: "Chiến dịch 3",
//     roas: "1,7",
//   },
//   {
//     ad_name: "Chiến dịch 4",
//     roas: "1,5",
//   },
//   {
//     ad_name: "Chiến dịch 5",
//     roas: "1,2",
//   },
// ];
