import { createApp } from "@haravan/app-sdk";
import options from 'secret/options';

let app: any = null;

const AppSdk = (domain: string) => {
  if (app == null) {
    const appConfig = {
      apiKey: `${options.client_id}`,
      shopOrigin: domain,
      embedded: true,
    };

    app = createApp(appConfig);
  }

  return app;
};

export default AppSdk;
