import { useReducer } from 'react';
import Context from './Context';
import { initialState, Reducer, actions } from './store';
import { useAmazingStoreLog } from 'hooks';

const Provider = (props: any) => {
  const amazingStoreLog = useAmazingStoreLog();
  const [state, dispatch] = useReducer(amazingStoreLog(Reducer), initialState);
  return (
    <Context.Provider value={{state, dispatch, actions}}>
      {props.children}
    </Context.Provider>
  )
}

export default Provider;
