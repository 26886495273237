import { memo } from "react";
import { ReportProvider } from './context';
import { Main } from './components';

const Report = memo(() => {
  return (
    <ReportProvider>
      <Main />
    </ReportProvider>
  );
})

export default Report;
