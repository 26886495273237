import options from "secret/options";
import { queryString } from "services/helper";

const goToInstallApp = () => {
  const _queryString: any = queryString;
  const url = `${options.api_url}/api/app/install?orgid=${_queryString.orgid}`;
  window.location.href = url;
};

const goToEmbedApp = () => {
  let appUrl = `/admin/apps/${options.client_id}`;
  let url = `https://${
    options.myharavan
  }/admin/account/login?returnUrl=${encodeURIComponent(appUrl)}`;
  window.location.href = url;
};

export {
  goToInstallApp,
  goToEmbedApp,
}
