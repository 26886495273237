/* eslint-disable react-hooks/exhaustive-deps */
import Loading from "components/Loading";
import { useLocalStorage } from "hooks";
import { createContext, useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import TagManager from 'react-gtm-module';
import { goToEmbedApp, goToInstallApp } from "./hooks";
import { queryString } from "../helper";
import EmbbedApp from "./app";
import {
  DEFAULT_APP_DATA,
  DEFAULT_SHOP_DATA,
  DEFAULT_SHOP_STATUS_DATA,
  VERSION,
} from "./constants";
import { Iapp, IrootAppState, Ishop, IshopStatus } from "./types";
import options from "secret/options";
export const AppContext = createContext({});

export const AppProvider = memo((props: any) => {
  const [shop, setShop] = useState<Ishop>(DEFAULT_SHOP_DATA);
  const [shopStatus, setShopStatus] = useState<IshopStatus>(
    DEFAULT_SHOP_STATUS_DATA
  );
  const [app, setApp] = useState<Iapp>(DEFAULT_APP_DATA);
  const { i18n } = useTranslation<any>();
  const location: any = useLocation();
  const { getItem, setItem, removeItem } = useLocalStorage();
  const storageKey = "hrv_locale";

  const useEffectDidMount = (effect: React.EffectCallback) => {
    useEffect(effect, []);
  };

  useEffectDidMount(() => {
    const _queryString: any = queryString;
    const shopData: Ishop = {
      pageInit: true,
      shop: _queryString.shop,
      locale: getLocale(_queryString),
      orgid: _queryString.orgid,
      isIframe: window.self !== window.top,
    };
    setShop(shopData);
    // set servsion & data client to document
    (document as any)._app_client_tiktok = { _version: VERSION, _data: shopData };
  });

  const useEffectCheckGoToEmbedAppOrGoToInstallApp = (
    effect: React.EffectCallback
  ) => {
    useEffect(effect, [shop, location.pathname]);
  };

  useEffectCheckGoToEmbedAppOrGoToInstallApp(() => {
    if (shop.pageInit && !shop.isIframe) {
      // go to embed app
      // TODO: quá phức tạp cần refactor lại chỗ này
      if (location.pathname !== "/install") {
        if (location.pathname !== "/tiktok-business-plugin") {
          goToEmbedApp();
        } else {
          const _queryString: any = queryString;
          window.location.href = `${options.api_url}/api/tiktoks/login?orgid=${_queryString.orgid}`;
        }
      } else {
        goToInstallApp();
      }
      return;
    }
  });

  const useEffectSetEmbedApp = (effect: React.EffectCallback) => {
    useEffect(effect, [shop]);
  };

  useEffectSetEmbedApp(() => {
    if (shop.pageInit && shop.isIframe && shop.shop) {
      const apprunning: boolean = true;
      const app: Iapp = EmbbedApp(shop, apprunning);
      setApp(app);
    }
  });

  const useEffectFetchAppStatus = (effect: React.EffectCallback) => {
    useEffect(effect, [app.appRunning]);
  };

  useEffectFetchAppStatus(() => {
    if (app && app !== undefined && app.appRunning && !shopStatus.init) {
      const fetchAppStatus = async () => {
        const data = await app.fetchAppStatus();
        if (data.need_to_install_app) {
          return app.redirectToInstallApp();
        }
        const shopStatus: IshopStatus = {
          init: true,
          isLogin: data.is_login,
          isActive: data.is_active,
          isOnBoardingCompleted: data.is_on_boarding_completed,
          onBoardingStep: data.on_boarding_step,
          planStatus: data.plan_status,
          shopName: data.shop_name,
        };
        setShopStatus(shopStatus);
        // GTM
        (document as any).title = `${data?.shop_name} - Tiktok Ads`;
        (document as any).user_id = data?.user_id;
        (document as any).org_id = shop.orgid;
        (document as any).org_name = data?.shop_name;
        TagManager.initialize({gtmId: "GTM-MWV7MK8"});
      };
      fetchAppStatus();
    }
  });

  const useEffectChangeLanguage = (effect: React.EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, [shop.locale]);
  };

  useEffectChangeLanguage(() => {
    if (shop && shop.locale !== "") {
      i18n.changeLanguage(shop.locale);
    }
  });

  const setLocale = (locale: string) => {
    setItem(storageKey, locale);
    setShop((currentState) => ({ ...currentState, locale: locale }));
  };

  const getLocale = (_queryString: any): string => {
    const locale: any = getItem(storageKey);
    const queryLocale = _queryString.locale;
    queryLocale === "vi" && removeItem(storageKey);
    return locale ? locale : queryLocale;
  };

  const isReady = (): boolean => {
    // kiểm tra dữ liệu shop đã được set
    // kiểm tra app đã được running
    return shop && shop.pageInit && app && app.appRunning && shopStatus.init;
  };

  const rootAppState: IrootAppState = {
    shop,
    shopStatus,
    app,
    setLocale,
  };

  return (
    <>
      {isReady() ? (
        <AppContext.Provider value={rootAppState}>
          {props.children}
        </AppContext.Provider>
      ) : (
        <Loading />
      )}
    </>
  );
});
