import { LazyLoad } from "utils/Loadable";

// Lazy Import Public Page
const Home = LazyLoad({ _cb: () => import("pages/Home") });
const Login = LazyLoad({
  _cb: () => import("pages/Login"),
});
const Onboarding = LazyLoad({
  _cb: () => import("pages/Onboarding"),
});
const Install = LazyLoad({ _cb: () => import("pages/Install") });
const InstallRejected = LazyLoad({
  _cb: () => import("pages/Install/Rejected"),
});

const Notfound = LazyLoad({ _cb: () => import("pages/Notfound") });
const NoLanguage = LazyLoad({
  _cb: () => import("pages/NoLanguage"),
});
const NoMobile = LazyLoad({ _cb: () => import("pages/NoMobile") });
const ConsultantSale = LazyLoad({ _cb: () => import("pages/ConsultantSale") });
const Settings = LazyLoad({ _cb: () => import("pages/Settings") });
const Products = LazyLoad({ _cb: () => import("pages/Products") });
const SyncProducts = LazyLoad({
  _cb: () => import("pages/SyncProducts/"),
});

export {
  Home,
  Install,
  Login,
  Onboarding,
  InstallRejected,
  Notfound,
  NoLanguage,
  NoMobile,
  ConsultantSale,
  Settings,
  Products,
  SyncProducts,
};
