import { Ishop, Iapp, IshopStatus } from "./types";

export const DEFAULT_SHOP_DATA: Ishop = {
  pageInit: false,
  shop: "",
  locale: "",
  orgid: "",
  isIframe: false,
};

export const DEFAULT_APP_DATA: Iapp = {
  appRunning: false,
};

export const DEFAULT_SHOP_STATUS_DATA: IshopStatus = {
  init: false, // TODO by pass
  isActive: false, // TODO by pass
  isLogin: false,
  isOnBoardingCompleted: false,
  onBoardingStep: 0,
  planStatus: "",
  shopName: "",
};

export const VERSION = 'v0.0.4';
