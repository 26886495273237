/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, memo } from "react";
import Context from "./Context";
import { useApp } from "services/embed";
import { IstateAuth } from "./types";
import { queryString } from "../helper";
// import options from "secret/options";
import { AUTH_STATE_DEFAULT } from "./constants";

const initialAuthState: IstateAuth = AUTH_STATE_DEFAULT;

const Provider = memo((props: any) => {
  const { shopStatus, app, redirectToUrl } = useApp();
  const [stateAuth, setStateAuth] =
    useState<IstateAuth>(initialAuthState);
  let interval: any = null;

  const useEffectDidMount = (effect: React.EffectCallback) => {
    useEffect(effect, [effect]);
  };

  useEffectDidMount(() => {
    if (!stateAuth.ready) {
      checkLogin();
    }
  });

  const useEffectIntervalFetchApiCheckLogin = (effect: React.EffectCallback) => {
    useEffect(effect, [stateAuth.isLogin, stateAuth.isLoginRuning]);
  };

  useEffectIntervalFetchApiCheckLogin(() => {
    if (stateAuth.isLoginRuning && !stateAuth.isLogin) {
      interval = setInterval(() => {
        fetchAppStatusCheckLogin();
      }, 5000);
    }

    return () => {
      clearInterval(interval);
    };
  });

  const fetchAppStatusCheckLogin = async () => {
    const data = await app.fetchAppStatus();
    if (data?.is_login) {
      setLogedIn(true);
      clearInterval(interval);
    }
  };

  const checkLogin = () => {
    setLogedIn(shopStatus.isLogin);
    setFacebookAdsEnabled(shopStatus.isActive);
    setOnboardingCompleted(shopStatus.isOnBoardingCompleted);
    setReady(true);
  };

  const login = () => {
    const _queryString: any = queryString;
    const _window: any  = window;
    const origin: string = _window.location.origin;
    // _window.open(`${options.api_url}/api/tiktoks/login?orgid=${_queryString.orgid}`,'_blank','noopener');
    setLoginRuning(true);
    redirectToUrl(`${origin}/tiktok-business-plugin?orgid=${_queryString.orgid}`);
    // _window.top.close();
  };

  const setReady = (payload: boolean) => {
    setStateAuth((preState) => ({ ...preState, ready: payload }));
  };

  const setLogedIn = (payload: boolean) => {
    setStateAuth((preState) => ({ ...preState, isLogin: payload }));
  };

  const setLoginRuning = (payload: boolean) => {
    setStateAuth((preState) => ({ ...preState, isLoginRuning: payload }));
  };

  const setFacebookAdsEnabled = (payload: boolean) => {
    setStateAuth((preState) => ({
      ...preState,
      isActive: payload,
    }));
  };

  const setOnboardingCompleted = (payload: boolean) => {
    setStateAuth((preState) => ({
      ...preState,
      isOnboardingCompleted: payload,
    }));
  };

  const ready = () => {
    return stateAuth.ready;
  };

  return (
    <>
      {ready() && (
        <Context.Provider
          value={{
            login,
            setLogedIn,
            setOnboardingCompleted,
            stateAuth,
          }}
        >
          {props.children}
        </Context.Provider>
      )}
    </>
  );
});

export default Provider;
