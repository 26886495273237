import { useNavigate } from "react-router-dom";
// import { useApp } from "services/embed";

// Hook
export function useNavigateOmni() {
  const navigate = useNavigate();
  // const { navigateOmni: navigateAppOmni } = useApp();

  const navigateOmni = (path: any) => {
    // navigateAppOmni(`/marketing/apps/3bb070be9dba65f37834f73317ce245f${path}`);
    navigate(path);
  };
  return navigateOmni;
}
