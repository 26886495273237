import { Fragment } from "react";
import { Route } from "react-router-dom";
import { PublicRoutes, ProtectedRoutes } from "./routes";
import { MasterLayout } from "./components/Layout";
import { Notfound, NoLanguage } from "pages";
import { AuthProvider, RequireAuth } from "services/auth";
import { AppProvider } from "services/embed";
import SentryWrapper from "services/sentry/SentryWrapper";

function App() {
  return (
    <div className="App hrv-tta-mb-150 hrv-tta-md-mb-0">
      <AppProvider>
        <AuthProvider>
          <SentryWrapper>
            {PublicRoutes.map((route: any, index: number) => {
              const Component = route.component;
              let Layout: any = MasterLayout;

              if (route.layout) {
                Layout = route.layout;
              } else if (null === route.layout) {
                Layout = Fragment;
              }

              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Layout>
                      <Component />
                    </Layout>
                  }
                />
              );
            })}

            {ProtectedRoutes.map((route: any, index: number) => {
              const Component = route.component;
              let Layout: any = MasterLayout;

              if (route.layout) {
                Layout = route.layout;
              } else if (null === route.layout) {
                Layout = Fragment;
              }

              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Layout>
                      <RequireAuth>
                        <Component />
                      </RequireAuth>
                    </Layout>
                  }
                />
              );
            })}

            <Route path="/no-support-language" element={<NoLanguage />} />
            <Route path="*" element={<Notfound />} />
          </SentryWrapper>
        </AuthProvider>
      </AppProvider>
    </div>
  );
}

export default App;
